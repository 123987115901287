import React, { useRef } from 'react';
import { Popup } from 'devextreme-react/popup';
import Form, { Item, Label, RequiredRule, ButtonItem, ButtonOptions } from 'devextreme-react/form';
import LoadIndicator from 'devextreme-react/load-indicator';
import { useLocalization } from '../../contexts/LocalizationProvider';
import { useObjectState } from '../../utils/Hooks';
import { fetchData, getErrorData } from '../../helpers/Fetch';
import { notify } from '../../utils/Notify';
import Log from '../../utils/Log';
import './passwordChanger.scss';

export default function PasswordChanger({ popupVisible, setPopupVisible }) {
    const { t } = useLocalization();

    const PasswordChangerValidationGroup = 'passwordChangerValidationGroup';
    const FormOptions = {
        id: 'passwordChanger',
        showColonAfterLabel: false,
        labelLocation: 'top',
        colCount: 'auto',
        validationGroup: PasswordChangerValidationGroup
    };

    const [state, setState, getValue, setValue, getBinding] = useObjectState({
        popupData: {
            popupOldPassword: null,
            popupNewPassword: null,
            popupConfirmPassword: null
        },
        popupLoading: false
    });

    const [popupData, setPopupData] = getBinding('popupData');
    const [popupLoading, setPopupLoading] = getBinding('popupLoading');

    const formRef = useRef();
    const onFormInitialized = (e) => {
        formRef.current = e.component;
    };

    const onShowing = (e) => {
        setTimeout(() => {
	        const editor = formRef.current.instance()?.getEditor('popupOldPassword');
	        if (editor !== undefined) editor?.focus();
        }, 500);
    };

    const onSubmit = (e) => {
        const doSubmit = (validation) => {
            const brokenRules = validation.brokenRules.filter(rule => rule.validator.element().offsetParent != null);
            if (brokenRules.length == 0) {
                setPopupLoading(true);
                const query = "userApi/passwordChange";
                fetchData(query, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        oldPassword: popupData.popupOldPassword,
                        newPassword: popupData.popupNewPassword,
                        confirmPassword: popupData.popupConfirmPassword
                    })
                }, true, 'text').then(() => {
                    notify(t(100202), 'success');
                    setPopupVisible(false);
                    setState({
                        popupData: {
                            popupOldPassword: null,
                            popupNewPassword: null,
                            popupConfirmPassword: null
                        }
                    });
                }).catch((error) => {
                    handleSaveError(error);
                }).finally(() => {
                    setPopupLoading(false);
                });
            }
            else {
                brokenRules[0].validator.focus();
            }
        };

        const validation = e.validationGroup.validate();
        if (validation.complete) {
            validation.complete.then((validation) => {
                doSubmit(validation);
            });
        } else {
            doSubmit(validation);
        }
    };

    const handleSaveError = (error) => {
        return new Promise((resolve, reject) => {
            if (error) {
                getErrorData(error, 'error').then(data => {
                    Log.error(error);
                    notify(data && data.hasOwnProperty('message') && data.message ? data.message : t(300006), 'error');
                    resolve(data);
                });
            } else {
                notify(t(300006), 'error');
                resolve(null);
            }
        });
    }

    return (
        <Popup
            visible={popupVisible}
            onShowing={onShowing}
            onHiding={(e) => {
                setPopupVisible(false);
                setState({
                    popupLoading: false,
                    popupData: {
                        popupOldPassword: null,
                        popupNewPassword: null,
                        popupConfirmPassword: null
                    }
                });
            }}
            hideOnOutsideClick={true}
            showTitle={false}
            width={330}
            height={365.7}
        >
            <div className={'title'}>{t(400004)}</div>
            <form>
                <Form formData={popupData} ref={formRef} onInitialized={onFormInitialized} {...FormOptions}>
                    <Item
                        dataField={'popupOldPassword'}
                        editorType={'dxTextBox'}
                        editorOptions={{ stylingMode: 'filled', placeholder: t(400005), mode: 'password', disabled: popupLoading }}
                    >
                        {popupVisible && <RequiredRule message={t(300000)} />}
                        <Label visible={false} />
                    </Item>
                    <Item
                        dataField={'popupNewPassword'}
                        editorType={'dxTextBox'}
                        editorOptions={{ stylingMode: 'filled', placeholder: t(400006), mode: 'password', disabled: popupLoading }}
                    >
                        {popupVisible && <RequiredRule message={t(300000)} />}
                        <Label visible={false} />
                    </Item>
                    <Item
                        dataField={'popupConfirmPassword'}
                        editorType={'dxTextBox'}
                        editorOptions={{ stylingMode: 'filled', placeholder: t(400007), mode: 'password', disabled: popupLoading }}
                    >
                        {popupVisible && <RequiredRule message={t(300000)} />}
                        <Label visible={false} />
                    </Item>
                    <ButtonItem name="login">
                        <ButtonOptions
                            width={'100%'}
                            type={'default'}
                            onClick={onSubmit}
                        >
                            <span className="dx-button-text">
                                {popupLoading ? <LoadIndicator height={25} width={25} visible={true} style={{ marginTop: -4 }} /> : t(100106)}
                            </span>
                        </ButtonOptions>
                    </ButtonItem>
                </Form>
            </form>
        </Popup>
    );
};