﻿const roundToPrecision = (value: number | null, precision: number) => {
    if (value != null) return Math.round(value * (1 / precision)) / (1 / precision);
    else return value;
}

const round = (value: number | null, decimals: number) => {
    if (value != null) return Math.round(value * Math.pow(10, decimals)) / Math.pow(10, decimals);
    else return value;
}

const format = (value: number | null, decimals: number) => {
    if (value != null) return value.toFixed(decimals);
    else return value;
}

//const decimalNumber1 = { type: 'fixedPoint', precision: 1 };
//const decimalNumber2 = { type: 'fixedPoint', precision: 2 };
//const decimalNumber3 = { type: 'fixedPoint', precision: 3 };
const decimalNumber1 = "#0.#";
const decimalNumber2 = "#0.##";
const decimalNumber3 = "#0.###";

const Number = {
    round: round,
    roundToPrecision: roundToPrecision,
    format: format,
    decimalNumber1: decimalNumber1,
    decimalNumber2: decimalNumber2,
    decimalNumber3: decimalNumber3,
}

export { Number };