import Drawer from 'devextreme-react/drawer';
import ScrollView from 'devextreme-react/scroll-view';
import React, { useState, useCallback, useRef, useEffect } from 'react';
import SideNavigationMenu from '../../components/sideNavigationMenu/SideNavigationMenu';
import Header from '../../components/header/Header';
import './sideNav.scss';
import { useScreenSize } from '../../contexts/ScreenSizeProvider';
import { Template } from 'devextreme-react/core/template';
import { useMenuPatch } from '../../helpers/Patches';
import { Miscellaneous } from '../../helpers';
import {
    Routes,
    Route,
    useMatch,
    useLocation,
    useNavigate,
} from "react-router-dom";
import NotFound from '../../views/error/NotFound';
import { PersistentSettingsService } from '../../services';
import { StorageKeys } from '../../helpers/Constants';
import { usePrevious } from '../../utils/Hooks';

export default function ({ view, menuItem, menuItems, component }) {
    //const height = useWindowHeight();
    const scrollViewRef = useRef();
    const history = useLocation();
    const navigate = useNavigate();

    const { isXSmall, isSmall, isLarge, isXLarge, height } = useScreenSize();
    const [patchCssClass, onMenuReady] = useMenuPatch();
    const [settingsLoaded, setSettingsLoaded] = useState(false);
    const [menuStatus, setMenuStatus] = useState(MenuStatus.Closed);
    const [_, setStateUpdater] = useState(false);
    const x = useMatch(history.pathname);
    const path = x.pattern.path;

    const fullScreenMode = document.fullscreenElement != null;

    const toggleMenu = useCallback(({ event }) => {
        if (!settingsLoaded)
            return;

        let newStatus = menuStatus === MenuStatus.Closed
            ? MenuStatus.Opened
            : MenuStatus.Closed;

		PersistentSettingsService.setInteger(Miscellaneous.getStorageKey(StorageKeys.AppMenuState), newStatus, "SideNav.js -> toggleMenu")
			.then(_ => {
				setMenuStatus(newStatus);
			});
        event.stopPropagation();
    }, [menuStatus, settingsLoaded]);

    const temporaryOpenMenu = useCallback(() => {
        if (!settingsLoaded)
            return;

        setMenuStatus(
            prevMenuStatus => prevMenuStatus === MenuStatus.Closed
                ? MenuStatus.TemporaryOpened
                : prevMenuStatus
        );
    }, [settingsLoaded]);

    const onNavigationChanged = useCallback(({ itemData: { route }, event, node }) => {
        if (menuStatus === MenuStatus.Closed || !menuItem || !menuItem.route || !menuItem.route.path || !route || !route.path) {
            event.preventDefault();
            return;
        }

        const newPath = `/${menuItem.route.path}/${route.path}`;

        if (node.selected && history && history.path && history.pathname === newPath) {
            event.preventDefault();
            return;
        }

        Miscellaneous.navigate(newPath, navigate, event);
        //scrollViewRef.current.instance.scrollTo(0);

        if (menuStatus === MenuStatus.TemporaryOpened) {
            setMenuStatus(MenuStatus.Closed);
            event.stopPropagation();
        }
    }, [history, menuStatus, menuItem, settingsLoaded]);

    useEffect(() => {
        // Load menu state
        PersistentSettingsService.get(Miscellaneous.getStorageKey(StorageKeys.AppMenuState), "SideNav.js -> useEffect[]")
            .then(s => {
                let newState;
                if (s === null) {
                    // default
                    newState = isLarge || isXLarge ? MenuStatus.Opened : MenuStatus.Closed;
                } else {
	                newState = s;
                }
                setMenuStatus(newState);
            setSettingsLoaded(true);
            });

        let maximizedElement = document.body;
        if (maximizedElement != null) {
            maximizedElement.addEventListener("fullscreenchange", onFullScreenchange);
        }
    }, []);

    const onFullScreenchange = (e) => {
        setStateUpdater(x => !x);
    };


    // NOTE PLM: fix when resizing window
    setTimeout(function () {
        let el = document.getElementsByClassName('dx-overlay-wrapper dx-drawer-panel-content');
        if (el.length > 0) {
            el = el[0];
            el.style.height = (height - (fullScreenMode ? 0 : 56)) + 'px';
        }
    }, 100);


    return (
        <div className={'side-nav-outer-toolbar'}>
            {!fullScreenMode && <Header
                key={"sideNavHeader"}
                className={'layout-header'}
                menuToggleEnabled
                toggleMenu={toggleMenu}
                //title="OPTIMO"
                location={history}
                menuItems={menuItems}
            />}
            <Drawer
                className={['drawer', patchCssClass].join(' ')}
                position={'before'}
                openedStateMode={'shrink'}
                revealMode={'expand'}
                minSize={0}
                maxSize={250}
                shading={false}
                opened={menuStatus === MenuStatus.Closed || fullScreenMode ? false : true}
                template={'menu'}
            >
                <div className={'container'}>
                    {/* <ScrollView ref={scrollViewRef} className={'layout-body with-footer'}>*/}
                    <div style={{ overflowY: 'auto' }} className={'layout-body with-footer'}>
                        <div className={'content'} style={{ height: height - (fullScreenMode ? 0: 56), paddingLeft: (isXSmall || fullScreenMode) ? 0 : (isSmall ? 20 : 40), paddingRight: (isXSmall || fullScreenMode) ? 0 : (isSmall ? 20 : 40) }}
                        >
                            <Routes>
                                <Route path={":entity"} element={component} />
                                <Route path={":entity/:view"} element={component} />
                                <Route path={":entity/:view/:id"} element={component} />
                                {/*<Route path={`${view}/:entity/:view?/:id?`} element={component}>*/}
                                {/*<Route path="*" element={<NotFound />}>*/}

                                {/*       </Route>*/}
                            </Routes>
                        </div>
                        {/*<div className={'content-block'}>
                            {React.Children.map(children, item => {
                                return item.type === Footer && item;
                            })}
                        </div>*/}
                        {/*</ScrollView>*/}
                    </div>
                </div>
                <Template name={'menu'}>
                    <SideNavigationMenu
                        menuItems={menuItem.items}
                        compactMode={menuStatus === MenuStatus.Closed}
                        selectedItemChanged={onNavigationChanged}
                        openMenu={temporaryOpenMenu}
                        onMenuReady={onMenuReady}
                        view={view}
                        location={history}
                    >
                    </SideNavigationMenu>
                </Template>
            </Drawer>
        </div>
    );
}

const MenuStatus = {
    Closed: 1,
    Opened: 2,
    TemporaryOpened: 3
};