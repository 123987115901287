import { isDate as isDateDateFns } from 'date-fns';

const valueOrDefault = (value, defaultValue, allowBlankvalue = false) => {
    return window.valueOrDefault(value, defaultValue, allowBlankvalue);
}

const typeOf = (value) => {
    return window.typeOf(value);
}

const isEmpty = (value, allowEmptyString = false) => {
    return window.isEmpty(value, allowEmptyString);
}

const isArray = (value) => {
    return window.isArray(value);
}

const isDate = (value) => {
    return isDateDateFns(value); //window.isDate(value);
}

const isMSDate = (value) => {
    return window.isMSDate(value);
}

const isObject = (value) => {
    return window.isObject(value);
}

const isSimpleObject = (value) => {
    return window.isSimpleObject(value);
}

const isPrimitive = (value) => {
    return window.isPrimitive(value);
}

const isFunction = (value) => {
    return window.isFunction(value);
}

const isNumber = (value) => {
    return window.isNumber(value);
}

const isNumeric = (value) => {
    return window.isNumeric(value);
}

const isString = (value) => {
    return window.isString(value);
}

const isBoolean = (value) => {
    return window.isBoolean(value);
}

const isDefined = (value) => {
    return window.isDefined(value);
}

const arrayToValue = (value) => {
    return Type.isArray(value) ? (value.length == 0 ? null : value[0]) : value; 
}

const Type = {
    valueOrDefault: valueOrDefault,
    typeOf: typeOf,
    isEmpty: isEmpty,
    isArray: isArray,
    isDate: isDate,
    isMSDate: isMSDate,
    isObject: isObject,
    isSimpleObject: isSimpleObject,
    isPrimitive: isPrimitive,
    isFunction: isFunction,
    isNumber: isNumber,
    isNumeric: isNumeric,
    isString: isString,
    isBoolean: isBoolean,
    isDefined: isDefined,
    arrayToValue: arrayToValue
}

export { Type };