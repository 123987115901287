import { fetchData } from '../helpers/Fetch';
import { GridColumnResizingMode } from '../helpers/Constants';
import { Text } from '../helpers';
import { AuthenticationService } from '../services';

const Types = {
    string: 0,
    integer: 1,
    decimal: 2,
    object: 3
};

const get = (key, tag = null) => {
    return new Promise((resolve, reject) => {
        getInternal(key, tag).then((data) => {
            //console.log(key);
            //console.log(data);
            resolve(data);
        });
    });
};

const remove = (key, tag = null) => {
    return new Promise((resolve, reject) => {
        fetchData("persistentSettingsApi" + Text.objectToQueryString({
            key: encodeURIComponent(key),
            resizingMode: getResizingMode(key),
            tag: `clientUserId=${AuthenticationService.currentUser.id} ~ url=${window.location.href}` + (tag ? ` ~ ${tag}` : "")
        }), {
	        method: 'DELETE'
        }).then((data) => {
            getSuccess('DELETE', key, data, resolve);
        }).catch(e => {
            getError('DELETE', key, e, resolve);
        });
    });
};

const getSuccess = (mode, key, data, resolve) => {
    if (data && data.data != null && data.data != 'null') {
        if (data.type == Types.integer) {
            console.log('persistentSettings', mode, key, parseInt(data.data));
            resolve(parseInt(data.data));
        } else if (data.type == Types.decimal) {
            console.log('persistentSettings', mode, key, parseFloat(data.data));
            resolve(parseFloat(data.data));
        } else if (data.type == Types.object) {
            try {
                console.log('persistentSettings', mode, key, JSON.parse(data.data));
                resolve(JSON.parse(data.data));
            } catch (e) {
                console.log('persistentSettings', mode, key, null);
                resolve(null);
            }
        } else {
            console.log('persistentSettings', mode, key, data.data);
            resolve(data.data);
        }
    } else {
        console.log('persistentSettings', mode, key, null);
        resolve(null);
    }
};

const getError = (mode, key, error, resolve) => {
    console.error(error);
    console.log('persistentSettings', mode, key, null);
    resolve(null);
};

const getInternal = (key, tag = null) => {
    return new Promise((resolve, reject) => {
        fetchData("persistentSettingsApi" + Text.objectToQueryString({
	        key: encodeURIComponent(key),
	        resizingMode: getResizingMode(key),
	        tag: `clientUserId=${AuthenticationService.currentUser.id} ~ url=${window.location.href}` + (tag ? ` ~ ${tag}` : "")
        })).then((data) => {
            /*if (data && data.data != null && data.data != 'null') {
                if (data.type == Types.integer) {
                    console.log('persistentSettings', parseInt(data.data));
                    resolve(parseInt(data.data));
                } else if (data.type == Types.decimal) {
                    console.log('persistentSettings', parseFloat(data.data));
                    resolve(parseFloat(data.data));
                } else if (data.type == Types.object) {
                    try {
                        console.log('persistentSettings', JSON.parse(data.data));
                        resolve(JSON.parse(data.data));
                    } catch (e) {
                        console.log('persistentSettings', null);
                        resolve(null);
                    }
                } else {
                    console.log('persistentSettings', data.data);
                    resolve(data.data);
                }
            } else {
                console.log('persistentSettings', null);
                resolve(null);
            }*/
            getSuccess('GET', key, data, resolve);
        }).catch(e => {
            /*console.error(e);
            console.log('persistentSettings', null);
            resolve(null);*/
            getError('GET', key, e, resolve);
        });
    });
};

const setString = (key, data, tag = null) => {
    return set(key, data, Types.string, tag);
};

const setInteger = (key, data, tag = null) => {
    return set(key, data, Types.integer, tag);
};

const setDecimal = (key, data, tag = null) => {
    return set(key, data, Types.decimal, tag);
};

const setObject = (key, data, tag = null) => {
    return set(key, data, Types.object, tag);
};

const getResizingMode = (key) => {
    return `${GridColumnResizingMode && (key.endsWith('Grid') || key.endsWith('GridLookup'))}`;
}

const set = (key, data, type = Types.string, tag = null) => {
    return new Promise((resolve, reject) => {
        fetchData("persistentSettingsApi" + Text.objectToQueryString({
	        key: encodeURIComponent(key),
	        resizingMode: getResizingMode(key),
	        tag: `clientUserId=${AuthenticationService.currentUser.id} ~ url=${window.location.href}` + (tag ? ` ~ ${tag}` : "")
        }), {
	        method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ data: type == Types.string ? data : JSON.stringify(data), type: type })
        }, true, 'text').then((data1) => {
            console.log('persistentSettings', 'PUT', key, data, data1);
            resolve();
        }).catch(e => {
	        console.error(e);
            console.log('persistentSettings', 'PUT', key, data, null);
            reject();
        });
    });
};

export const PersistentSettingsService = {
    get,
    set,
    remove,
    setString,
    setInteger,
    setDecimal,
    setObject
}