import { AuthenticationService } from '../services';
import { Identifier, Basename } from './Constants';
import { Type } from '.';
import isEqual from 'lodash.isequal';
import pick from 'lodash.pick';

/*const getStorageKey = (key: string, uniquePerUser: boolean = true): string => {
    return `${Identifier}.${key}${uniquePerUser && AuthenticationService.isLoggedIn() ? '|' + AuthenticationService.currentUser.id : ''}`;
}*/
const getStorageKey = (key: string, uniquePerUser: boolean = false): string => {
    return `${Identifier}.${key}${uniquePerUser && AuthenticationService.isLoggedIn() ? '|' + AuthenticationService.currentUser.id : ''}`;
}

const argbToRgba = (hex: string): string => {
    if (hex == '' || hex == null) return '#fff';
    return hex.replace(/#(..)(......)/, '#$2$1');
}

const isObject = (o: any) => o === Object(o) && !Array.isArray(o);

const getNotEmptyKeys = (object: any) => Object.keys(object).filter(o => object[o] != undefined && object[o] != null && (!Type.isArray(object[o]) || object[o].length > 0));

const removeEmptyKeys = (object: any) => {
    return getNotEmptyKeys(object).reduce((acc: any, k: any) => {
        acc[k] = object[k];
        return acc;
    }, {});
};

const areArraysEqual = (array1: any[], array2: any[]) => {
    const sorted1 = Array.isArray(array1) ? array1.sort() : array1;
    const sorted2 = Array.isArray(array2) ? array2.sort() : array2;
    return isEqual(sorted1, sorted2);
};

const navigate = (url: string, history: any, e: any, handleCtrl: boolean = true) => {
    url = url.replace(/^\//, '')
    if (handleCtrl && e.ctrlKey) {
        window.open(`${Basename}/${url}`);
    } else {
        history(`/${url}`);
    }
};

const getPropertyValue = (object: any, property: string) => {
    return object.hasOwnProperty(property) ? object[property] : null;
};

//const pick = (...props: any) => (o: any) => props.reduce((a: any, e: any) => ({ ...a, [e]: o[e] }), {});

const Miscellaneous = {
    getStorageKey: getStorageKey,
    argbToRgba: argbToRgba,
    isObject: isObject,
    isEqual: isEqual,
    getNotEmptyKeys: getNotEmptyKeys,
    removeEmptyKeys: removeEmptyKeys,
    areArraysEqual: areArraysEqual,
    navigate: navigate,
    getPropertyValue: getPropertyValue,
    pick: pick
}

export { Miscellaneous };