import * as React from 'react';
import { useEffect, useState } from 'react';
import Router from './utils/router/Router';
import { fetchData } from './helpers/Fetch';
import { LocalizationProvider } from './contexts/LocalizationProvider';
import { ScreenSizeProvider, ScreenSizeContext, } from './contexts/ScreenSizeProvider';
import { AuthenticationService } from './services/AuthenticationService';
import { ApiActions } from './helpers/Constants';
import ErrorBoundary from './components/ErrorBoundary';
import Log from './utils/Log';
import config from 'devextreme/core/config';
import { licenseKey } from './devextreme-license';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
// styles
import 'devextreme/dist/css/dx.common.css';
import './themes/generated/theme.bts.css';
import './themes/generated/theme.bts.dark.css';
import './styles.scss';

const theme = createTheme({
    palette: {
        primary: {
            main: 'rgba(0, 106, 77, 1)'
        },
        secondary: {
            main: '#f7f7f7',
            light: '#ededed'
        }
    }
});

// global config
config({
    floatingActionButtonConfig: {
        position: { at: "right bottom", my: "right bottom", offset: "-16 -16", of: ".floating-action-target" },
        icon: 'rowfield'
    },
    licenseKey
});

function Title(props: any) {
    useEffect(() => {
        fetchData(`${ApiActions.GetTitle}?requireAuthentication=${AuthenticationService.isLoggedIn().toString()}`).then((data: any) => {
            document.title = data.title;
        }).catch(error => {
            Log.error(error);
        });
    }, []);
    return <>{props.children}</>
}

function App() {
    const [loading, setLoading] = useState(true);
    const [dictionary, setDictionary] = useState({});
    const [availableLanguages, setAvailableLanguages] = useState([]);
    const [defaultLanguage, setDefaultLanguage] = useState('');
    const [menuItems, setMenuItems] = useState([]);
    const [routes, setRoutes] = useState([]);

    const removeLoader = () => {
        const startupLoader = document.getElementById('startup-loader')
        if (startupLoader) {
            startupLoader.classList.add('available')
            setTimeout(() => {
                startupLoader.outerHTML = ''
            }, 2000)
        }
    };

    useEffect(() => {
        document.body.classList.add('bts');
        function doFetch() {
            fetchData(`${ApiActions.Initialize}?requireAuthentication=${AuthenticationService.isLoggedIn().toString()}`).then((data: any) => {
                let dictionary: any = {};
                let dictionaryData = JSON.parse(data.dictionary);
                for (var language in dictionaryData) {
                    dictionary[language.toLowerCase()] = dictionaryData[language];
                }
                setDictionary(dictionary);
                setAvailableLanguages(data.availableLanguages);
                setDefaultLanguage(data.defaultLanguage);
                setRoutes(data.routes);
                setMenuItems(data.menuItems);
            }).catch(error => {
                Log.error(error);
                setDictionary({});
                setAvailableLanguages([]);
                setDefaultLanguage('');
                setRoutes([]);
                setMenuItems([]);
            }).finally(() => {
                setLoading(false);
                removeLoader();
            });
        }
        doFetch();
    }, []);

    return (
        loading
            ? <></>
            : <ErrorBoundary>
                <ScreenSizeProvider>
                    <LocalizationProvider serverDefaultLocale={defaultLanguage} locale={AuthenticationService.isLoggedIn() ? AuthenticationService.currentUser.language : null} dictionary={dictionary} availableLocales={availableLanguages} >
                        <Title>
                            <ScreenSizeContext.Consumer>
                                {({ screenSizeClass }) => {
                                    return (
                                        <div className={`app ${screenSizeClass}`}>
                                            <ThemeProvider theme={theme}><Router routes={routes} menuItems={menuItems} /></ThemeProvider>
                                        </div>
                                    );
                                }}
                            </ScreenSizeContext.Consumer>
                        </Title>
                    </LocalizationProvider>
                </ScreenSizeProvider>
            </ErrorBoundary>
    );
}

export default App;