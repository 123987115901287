import * as React from 'react';
import {
    Route, Routes, Navigate, BrowserRouter as ReactRouter, useLocation, useNavigate, useParams
} from "react-router-dom";

import AuthorizeRoute from './AuthorizeRoute';
import Login from '../../views/Login';
import Logout from '../../views/Logout';
import NotFound from '../../views/error/NotFound';
import { SingleCard, SideNav } from '../../layouts';
import { useLocalization } from '../../contexts/LocalizationProvider';
import { Actions } from '../../helpers/Constants';
import { AuthenticationService } from '../../services';
import SubRouter from './SubRouter';
import { Miscellaneous } from '../../helpers';

//import Controls from '../../views/Controls';
//import MobileGrid from '../../views/MobileGrid';

import Refresh from '../../views/Refresh';


// views

const AdministrationUserGrid = React.lazy(() => import('../../views/administration/user/grid/Grid'));
const AdministrationUserEdit = React.lazy(() => import('../../views/administration/user/edit/Edit'));

const AdministrationCustomerGrid = React.lazy(() => import('../../views/administration/customer/grid/Grid'));
const AdministrationCustomerEdit = React.lazy(() => import('../../views/administration/customer/edit/Edit'));

const AdministrationLocationGrid = React.lazy(() => import('../../views/administration/location/grid/Grid'));
const AdministrationLocationEdit = React.lazy(() => import('../../views/administration/location/edit/Edit'));

const AdministrationCustomerSalesDocEdit = React.lazy(() => import('../../views/administration/salesdoc/edit/Edit'));
const AdministrationBuildingEdit = React.lazy(() => import('../../views/administration/building/edit/Edit'));

const AdministrationRoomEdit = React.lazy(() => import('../../views/administration/room/edit/Edit'));

const AdministrationEquipmentGrid = React.lazy(() => import('../../views/administration/equipment/grid/Grid'));
const AdministrationEquipmentEdit = React.lazy(() => import('../../views/administration/equipment/edit/Edit'));

const AdministrationEquipmentLookupLabelGrid = React.lazy(() => import('../../views/administration/equipmentLookupLabel/grid/Grid'));
const AdministrationEquipmentLookupLabelEdit = React.lazy(() => import('../../views/administration/equipmentLookupLabel/edit/Edit'));

const AdministrationOrderGrid = React.lazy(() => import('../../views/administration/order/grid/Grid'));
const AdministrationOrderEdit = React.lazy(() => import('../../views/administration/order/edit/Edit'));

//const AdministrationScheduler = React.lazy(() => import('../../views/administration/order/grid/Grid'));
const AdministrationScheduler = React.lazy(() => import('../../views/administration/scheduler/Scheduler'));

const AdministrationAppointmentGrid = React.lazy(() => import('../../views/administration/appointment/grid/Grid'));

const AdministrationReportGrid = React.lazy(() => import('../../views/administration/report/grid/Grid'));
const AdministrationReportEdit = React.lazy(() => import('../../views/administration/report/edit/Edit'));
const AdministrationTimePosEdit = React.lazy(() => import('../../views/administration/timepos/edit/Edit'));
const AdministrationMaterialPosEdit = React.lazy(() => import('../../views/administration/materialpos/edit/Edit'));

const AdministrationHoursGrid = React.lazy(() => import('../../views/administration/hours/grid/Grid'));

function Router({ routes, menuItems, ...restProps }) {
    const { t } = useLocalization();

    const findFirstRoute = (menuItems) => (
        menuItems.reduce((a, o) => {
            if (a) return a;
            if (o.route && o.route.path) return o.route;
            if (o.items) return findFirstRoute(o.items);
        }, null)
    );

    const getMenuItem = (menuItems, routeName) => (
        menuItems.reduce((a, o) => {
            if (a) return a;
            if (o.route && o.route.name === routeName) return o;
            if (o.items) return getMenuItem(o.items);
        }, null)
    );

    // from menu (only authorized are in collection)
    const defaultRoute = findFirstRoute(menuItems);
    const defaultRoutePath = defaultRoute ? `/${defaultRoute.path}` : (AuthenticationService.isLoggedIn() ? Actions.Logout : Actions.Login);

    // from menu (only authorized are in collection)
    const redirectRoutes = [];
    for (let i = 0; i < menuItems.length; i++) {
        let o = menuItems[i];
        if (o.route && o.route.path && o.items.length > 0) {
            let route = findFirstRoute(o.items);
            if (route) redirectRoutes.push({ from: `/${o.route.path}`, to: `/${o.route.path}/${route.path}` });
        }
    }
    const redirectList = redirectRoutes.map(o => (
        <Route key={o.from} path={o.from} element={<Navigate to={o.to} />} />
        //    <Navigate replace={true} key={o.from} exact from={o.from} to={o.to} />
    ));

    // components
    const components = {
        bts: {
            //"controls": <Controls />,
            //"mobileGrid": <MobileGrid />,

            "refresh": <Refresh />,

            "administration-user": <AdministrationUserGrid />,
            "administration-useredit": <AdministrationUserEdit />,

            "administration-customer": <AdministrationCustomerGrid />,
            "administration-customeredit": <AdministrationCustomerEdit />,
            "administration-customer-salesdocedit": <AdministrationCustomerSalesDocEdit />,
            
            "administration-location": <AdministrationLocationGrid />,
            "administration-locationedit": <AdministrationLocationEdit />,
            "administration-location-buildingedit": <AdministrationBuildingEdit />,
            "administration-location-roomedit": <AdministrationRoomEdit />,

            "administration-equipment": <AdministrationEquipmentGrid />,
            "administration-equipmentedit": <AdministrationEquipmentEdit />,

            "administration-orderstoerung": <AdministrationOrderGrid key={"AdministrationOrderStoerungGrid"} isStoerung={true}  />,
            "administration-orderstoerungedit": <AdministrationOrderEdit key={"AdministrationOrderStoerungEdit"}  isStoerung={true} />,

            "administration-orderrecall": <AdministrationOrderGrid key={"AdministrationOrderRecallGrid"} isStoerung={false} />,
            "administration-orderrecalledit": <AdministrationOrderEdit key={"AdministrationOrderRecallEdit"} isStoerung={false}  />,

            "administration-scheduler": <AdministrationScheduler />,

            "administration-appointment": <AdministrationAppointmentGrid />,

            "administration-lookupLabelEquipment": <AdministrationEquipmentLookupLabelGrid />,
            "administration-lookupLabelEquipmentedit": <AdministrationEquipmentLookupLabelEdit />,

            "administration-report": <AdministrationReportGrid />,
            "administration-reportedit": <AdministrationReportEdit />,
            "administration-report-timeposedit": <AdministrationTimePosEdit />,
            "administration-report-materialposedit": <AdministrationMaterialPosEdit />,

            "administration-hours": <AdministrationHoursGrid />
        }
    };

    // from routes (all records are in collection)
    const routeList = routes.map(o => {
        let subComponents = components[o.path];
        return <Route key={o.path} path={`/${o.path}` +(subComponents ? '/*' : '')} element={<AuthorizeRoute control={o.controls} {...restProps} render={(props) => (
            subComponents
                ? <SideNav {...props} menuItem={getMenuItem(menuItems, o.name)} menuItems={menuItems} view={o.path} component={<SubRouter route={o} components={subComponents} />} />
                : <NotFound />
        )} />} />
    });
    console.log(redirectList);
    console.log(routeList);
    return (
        <ReactRouter basename={`${process.env.REACT_APP_BASENAME}`} /*{history={history}}*/>
            <Routes>
                { /*<Navigate replace={true} exact from="/" to={defaultRoutePath} />*/
                }
                <Route path="/" element={<Navigate to={defaultRoutePath} />} />
                {redirectList}
                {routeList}
                <Route path={Actions.Login} element={
                    <SingleCard title={t(400000)} description="">
                        <Login defaultRoute={defaultRoutePath} />
                    </SingleCard>
                } />
                <Route path={Actions.Logout} element={<Logout />} />
                <Route path="*" element={<Navigate to={defaultRoutePath} />} /> { /*element={<NotFound />} />*/
                }
            </Routes>
        </ReactRouter>
    );
};

export default React.memo(Router, Miscellaneous.isEqual);

