import React, { useEffect, useRef, useCallback, useMemo } from 'react';
import TreeView from 'devextreme-react/tree-view';
import { useScreenSize } from '../../contexts/ScreenSizeProvider';
import { withRouter } from '../../helpers/Patches';
import './sideNavigationMenu.scss';

import * as events from 'devextreme/events';

const renderTreeViewItem = (item) => {
    let text = item.text;
    if (item.text.includes('{0}')) {
        text = (
            <>
                <span>{item.text.replace('{0}', '')}</span>
                <span id={`menu-tem-${item.id}`}></span>
            </>
        );
    } else {
        text = <span>{item.text}</span>
    }

    return (
        <>
            {item.icon && <i className={`dx-icon dx-icon-${item.icon}`}></i>}
            {text}
        </>
    );
}

const SideNavigationMenu = ({
    children,
    selectedItemChanged,
    openMenu,
    compactMode,
    onMenuReady,
    menuItems,
    location,
    view
}) => {

  //  const { isLarge, isXLarge } = useScreenSize();

    const treeViewRef = useRef();
    const wrapperRef = useRef();
    const getWrapperRef = useCallback((element) => {
        const prevElement = wrapperRef.current;
        if (prevElement) {
            events.off(prevElement, 'dxclick');
        }

        wrapperRef.current = element;
        events.on(element, 'dxclick', e => {
            openMenu(e);
        });
    }, [openMenu]);
    /*
    useEffect(() => {
        const treeView = treeViewRef.current && treeViewRef.current.instance;
        if (!treeView) {
            return;
        }

        if (location.pathname !== undefined) {
            const findActiveItem = (arr, nestingKey) => (
                arr.reduce((a, item) => {
                    if (a) return a;
                    if (item.itemData.route && location.pathname.startsWith(`/${view}/${item.itemData.route.path}`)) return item;
                    if (item[nestingKey]) return findActiveItem(item[nestingKey], nestingKey)
                }, null)
            );

            const activeItem = findActiveItem(treeView.getNodes(), 'items');
            if (activeItem) {
                treeView.selectItem(activeItem.key);
                treeView.expandItem(activeItem.key);
            }
        }

        if (compactMode) {
            treeView.collapseAll();
        }
    }, [location?.pathname, view, compactMode]);
    */
    const items = useMemo(() => {
        return menuItems.map(item => ({ ...item, expanded: true /*isLarge || isXLarge*/ }))
    }, [menuItems/*, isLarge || isXLarge*/]);

    return (
        <div
            className={'dx-swatch-bts-dark side-navigation-menu'}
            ref={getWrapperRef}
        >
            {children}
            <div className={'menu-container'}>
                <TreeView
                    ref={treeViewRef}
                    items={items}
                    keyExpr={'path'}
                    selectionMode={'single'}
                    focusStateEnabled={false}
                    expandEvent={'click'}
                    onItemClick={selectedItemChanged}
                    onContentReady={onMenuReady}
                    width={'100%'}
                    itemRender={renderTreeViewItem}
                />
            </div>
        </div>
    );
}

export default withRouter(SideNavigationMenu)