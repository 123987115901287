import { BehaviorSubject } from 'rxjs';
import Cookies from 'js-cookie';
import { fetchNotAuthorizedData, fetchData } from '../helpers/Fetch';
import { ApiActions, CookieNames, ClaimTypes, AccessRightType } from '../helpers/Constants';
import { Miscellaneous } from '../helpers';
//import * as jwtDecode from 'jwt-decode';
import jwtDecode from 'jwt-decode';
import Log from '../utils/Log';

class ApplicationUser {
    id: string;
    userName: string;
    firstName: string;
    lastName: string;
    language: string;
    isPersistent: boolean;
    //refreshTokenExpires: Date;
    accessToken: string;
}

interface AuthenticationResponse {
    accessToken: string;
}

const currentUserSubject = new BehaviorSubject<ApplicationUser | undefined | null>(createUser(Cookies.get(Miscellaneous.getStorageKey(CookieNames.AccessToken, false))));

export const AuthenticationService = {
    login,
    logout,
    isLoggedIn,
    isAuthorized,
    subscribe,
    get currentUser() { return currentUserSubject.value },
    currentRole,
    setCurrentUser,
    refreshFromCookie
}

function subscribe(next: (value: ApplicationUser) => void) {
    return currentUserSubject.subscribe(next);
}

function isLoggedIn() {
    return AuthenticationService.currentUser != null;
}

function isAuthorized(accessRightType: AccessRightType, control: number | number[]) {
    if (!isLoggedIn()) return false;

    let controlArray: number[];
    if (control instanceof Array) controlArray = control;
    else controlArray = [control];

    const accessToken: any = jwtDecode(AuthenticationService.currentUser.accessToken);
    return controlArray.some(o => accessToken[ClaimTypes.Control].includes(`${o.toString()}:${accessRightType}`));
}

function currentRole() {
    if (!isLoggedIn()) return null;
    
    const accessToken: any = jwtDecode(AuthenticationService.currentUser.accessToken);
    return accessToken[ClaimTypes.Role];
}

function createUser(data: string | null | undefined): ApplicationUser {
    try {
        if (data) {
            const decodedData: any = jwtDecode(data);
            let user = new ApplicationUser();
            user.accessToken = data;
            user.id = decodedData[ClaimTypes.Id];
            user.userName = decodedData[ClaimTypes.Id];
            user.firstName = decodedData[ClaimTypes.FirstName];
            user.lastName = decodedData[ClaimTypes.LastName];
            user.language = decodedData[ClaimTypes.Language];
            user.isPersistent = decodedData[ClaimTypes.IsPersistent].toLowerCase() === 'true';
            return user;
        } else {
            return null;
        }
    } catch (e) {
        return null;
    }
}

function setCurrentUser(data: AuthenticationResponse | undefined | null) {
    const clearAccessToken = () => {
        Cookies.remove(Miscellaneous.getStorageKey(CookieNames.AccessToken, false));
        currentUserSubject.next(null);
    };

    if (data) {
        try {
            const user: ApplicationUser = createUser(data.accessToken);
            /*let options = {};
            if (user.isPersistent) {
                // persistent cookie, cookie never expires, expiration is managed by server during api calls
                options = { expires: user.refreshTokenExpires };
            } else {
                // session cookie, expires after browser is closed or after timeout
            }
            Cookies.set(Miscellaneous.getStorageKey(CookieNames.AccessToken, false), user.accessToken, options);*/
            currentUserSubject.next(user);
        } catch (e) {
            clearAccessToken();
        }
    } else {
        clearAccessToken();
    }
}

function login(userName: string, password: string, isPersistent: boolean) {
    return new Promise<void>((resolve, reject) => {
        let data = new FormData();
        data.append("UserName", userName);
        data.append("Password", password);
        data.append("IsPersistent", isPersistent.toString());

        fetchNotAuthorizedData(ApiActions.Authenticate, {
            method: "POST",
            body: data
        }).then((data: any) => {
            setCurrentUser(data);
            resolve();
        }).catch((error) => {
            reject(error);
        });
    });
}

function logout(reload: boolean = true) {
    return new Promise<void>((resolve, reject) => {
        fetchData(ApiActions.RevokeToken, {}, false).catch((error) => {
        }).finally(() => {
            setCurrentUser(null);
            if(reload) window.location.reload();
            resolve( );
        });
    });
}

function refreshFromCookie() {
    setCurrentUser(createUser(Cookies.get(Miscellaneous.getStorageKey(CookieNames.AccessToken, false))));
}