import * as React from 'react';
import { Component } from 'react';
import {
    Route,
    Navigate,
} from "react-router-dom";
import { AuthenticationService } from '../../services';
import { QueryParameterNames, Actions, Basename, AccessRightType } from '../../helpers/Constants';
import NotAuthorized from '../../views/error/NotAuthorized';


export default class AuthorizeRoute extends React.Component {
    //subscription = null;

    constructor(props) {
        super(props);

        this.state = {
            loggedIn: this.isLoggedIn(),
            authorized: this.isAuthorized()
        };
    }

    componentDidMount() {
        //this.subscription = AuthenticationService.subscribe(() => this.authenticationChanged());
    }

    componentWillUnmount() {
        //if (this.subscription != null) this.subscription.unsubscribe();
    }

    isLoggedIn() {
        return AuthenticationService.isLoggedIn();
    }

    isAuthorized() {
        return this.props.control === undefined || AuthenticationService.isAuthorized(AccessRightType.ReadAccess, this.props.control);
    }

    /*authenticationChanged() {
        this.setState({ loggedIn: this.isLoggedIn(), authorized: this.isAuthorized() });
    }*/

    render() {
        const { loggedIn, authorized } = this.state;
        const re = new RegExp(`^(${Basename})`);
        const returnUrl = `${window.location.pathname.replace(re, '')}${window.location.search}${window.location.hash}`;
        const redirectUrl = `${Actions.Login}?${QueryParameterNames.ReturnUrl}=${encodeURI(returnUrl)}`;
        const { component: Component, ...rest } = this.props;
        /*return <Route {...rest}
            render={(props) => {*/
        if (loggedIn && authorized) {
            return this.props.render();
        } else if (loggedIn) {
            return <NotAuthorized />
        } else {
            return <Navigate to={redirectUrl} />
        }
    }
}



