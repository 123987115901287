import { useState, useCallback} from 'react';
import { useScreenSize } from '../contexts/ScreenSizeProvider';
import {
    useLocation, useNavigate, useParams
} from "react-router-dom";

export function useMenuPatch() {
  const { isSmall, isMedium } = useScreenSize();
  const [enabled, setEnabled] = useState(isSmall || isMedium);
  const onMenuReady = useCallback(() => {
    if (!enabled) {
      return;
    }

    setTimeout(() => setEnabled(false));
  }, [enabled]);

  return [enabled ? 'pre-init-blink-fix' : '', onMenuReady];
}


export function withRouter(Component) {
    function ComponentWithRouterProp(props) {
        let location = useLocation();
        let navigate = useNavigate();
        let params = useParams();
        return (
            <Component
                {...{ location, navigate, params, ...props }}
                router={{ location, navigate, params }}
            />
        );
    }

    return ComponentWithRouterProp;
}
