﻿import { startOfSecond, startOfMinute, getMinutes, subMinutes, addMinutes, toDate as toDateBase, isValid, parseJSON, parseISO, isEqual } from 'date-fns';
import { Type } from './Type';

const now = (): Date => {
    return startOfSecond(new Date());
}

const truncateToMinutesInterval = (date: Date, interval: number): Date => {
    const d = startOfMinute(toDateBase(date));
    const oldMinutes = getMinutes(d);
    const newtMinutes = Math.round(oldMinutes / interval) * interval;
    return addMinutes(subMinutes(date, oldMinutes), newtMinutes);
}

/*const toDate = (obj: any): Date => {
    let date = (obj != null && obj !== undefined) ? new Date(obj) : null;
    return isValid(date) ? date : null;
}*/

const toDate = (obj: any): Date | null => {
    if (Type.isEmpty(obj)) return null;
    const date = Type.isDate(obj) ? obj : parseISO(obj);
    return isValid(date) ? date : null;
};

const isISODate = (str: any): boolean => {
    const isoDateRegExp = new RegExp(/(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))/);
    return isoDateRegExp.test(str);
}

/*const toJSON = (date: any): string | null => {
    if (Type.isEmpty(date)) return null;
    if (Type.isString(date)) {
        return Type.isDate(new Date(date)) ? toJSON(new Date(date)) : null;
    } else {
        return Type.isDate(date) ? date.toJSON().replace(/Z$/i, '') : null;
    }
};*/

const toJSON = (date: any): string | null => {
    if (Type.isEmpty(date)) return null;
    if (Type.isString(date)) {
        return Type.isDate(new Date(date)) ? toJSON(new Date(date)) : null;
    } else {
        return Type.isDate(date) ? `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}T${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}:${date.getSeconds().toString().padStart(2, '0')}` : null;
    }
};

const Calendar = {
    now: now,
    truncateToMinutesInterval: truncateToMinutesInterval,
    toDate: toDate,
    toJSON: toJSON,
    isEqual: isEqual
}

export { Calendar };
