import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export default function Refresh() {
    const navigate = useNavigate();
    useEffect(() => {
        navigate((new URLSearchParams(window.location.search)).get('url'));
    }, []);

    return <></>;
}