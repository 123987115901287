import React, {
    createContext,
    useContext, useState, useEffect
} from 'react';
import { useMediaQueries } from '@react-hook/media-query';
import { useWindowSize } from '@react-hook/window-size/throttled';

const defaultValue = {
    screenSizeClass: '',
    isXSmall: false,
    isSmall: false,
    isMedium: false,
    isLarge: false,
    isXLarge: false,
    width: 0,
    height: 0
}

const ScreenSizeContext = createContext(defaultValue);

// Hook
const useWindowSizeLocal = () => {
    // Initialize state with undefined width/height so server and client renders match
    // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
    const [windowSize, setWindowSize] = useState({
        width: 0,
        height: 0,
    });
    useEffect(() => {
        // Handler to call on window resize
        function handleResize() {
            // Set window width/height to state
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }
        // Add event listener
        window.addEventListener("resize", handleResize);
        // Call handler right away so state gets updated with initial window size
        handleResize();
        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount
    return windowSize;
}

const ScreenSizeProvider = ({ children }) => {
    const { matches } = useMediaQueries({
        xSmall: '(max-width: 599.99px)',
        small: '(min-width: 600px) and (max-width: 959.99px)',
        medium: '(min-width: 960px) and (max-width: 1279.99px)',
        large: '(min-width: 1280px) and (max-width: 1919.99px)',
        xLarge: '(min-width: 1920px)'
    });

    // TODO: heck whether useWindowSize makes infinite redrawing
    const { width, height } = useWindowSizeLocal(); // [2357, 917]; //  useWindowSize(); 

    const isXSmall = () => matches.xSmall;

    const isSmall = () => matches.small;

    const isMedium = () => matches.medium;

    const isLarge = () => matches.large;

    const isXLarge = () => matches.xLarge;

    const getScreenSizeClass = () => {
        if (isXLarge()) {
            return 'screen-x-large';
        }
        if (isLarge()) {
            return 'screen-large';
        }
        if (isMedium()) {
            return 'screen-medium';
        }
        if (isSmall()) {
            return 'screen-small';
        }
        return 'screen-x-small';
    };

    return (
        <ScreenSizeContext.Provider value={{
            screenSizeClass: getScreenSizeClass(),
            isXSmall: isXSmall(),
            isSmall: isSmall(),
            isMedium: isMedium(),
            isLarge: isLarge(),
            isXLarge: isXLarge(),
            width,
            height
        }}>
            {children}
        </ScreenSizeContext.Provider>
    )
}

function useScreenSize() {
    const context = useContext(ScreenSizeContext);
    if (context === defaultValue) {
        throw new Error('useScreenSize must be used within ScreenSizeProvider');
    }
    return context;
}

const withScreenSize = Component => (
    props => (
        <ScreenSizeContext.Consumer>
            {context => <Component screenSizeContext={context} {...props} />}
        </ScreenSizeContext.Consumer>
    )
)

export { useScreenSize, ScreenSizeProvider, ScreenSizeContext, withScreenSize };
