import * as React from 'react';
import { useState, useEffect, createContext, useContext, useCallback } from 'react';
import deMessages from "devextreme/localization/messages/de.json";
import enMessages from "devextreme/localization/messages/en.json";
import frMessages from "devextreme/localization/messages/fr.json";
import itMessages from "devextreme/localization/messages/it.json";
import {
    locale as baseLocale,
    loadMessages as baseLoadMessages,
    formatDate as baseFormatDate,
    formatNumber as baseFormatNumber,
    formatMessage as baseFormatMessage
} from "devextreme/localization";
import config from 'devextreme/core/config';
import { licenseKey } from '../devextreme-license';
import { StorageKeys, DefaultLanguage, DefaultCurrency } from '../helpers/Constants';
import { Miscellaneous, Calendar, Number } from '../helpers';
//import Cookies from 'js-cookie';

const LocalizationContext = createContext({
    locale: null,
    t: (key, value = null) => null,
    formatMessage: (key, value = null) => null,
    formatDate: (value, format) => null,
    formatNumber: (value, format) => null,
    shortDateShortTime: null,
    shortDateLongTime: null,
    shortTime: null,
    shortDate: null,
    decimalNumber1: null,
    decimalNumber2: null,
    decimalNumber3: null
});
const useLocalization = () => useContext(LocalizationContext);

const locale = () => {
    return baseLocale();
};

function LocalizationProvider({ serverDefaultLocale: serverDefaultLocaleProp, locale: defaultLocale = undefined, availableLocales: defaultAvailableLocales = [], defaultCurrency = undefined, dictionary = undefined, ...props }) {
    const [availableLocales, setAvailableLocales] = useState(defaultAvailableLocales.map(item => item.toLowerCase()));
    const [serverDefaultLocale, setServerDefaultLocale] = useState(serverDefaultLocaleProp.toLowerCase());

    const checkLocale = (locale) => {
        let l = locale.toLowerCase();
        if (!availableLocales.includes(l)) l = DefaultLanguage.toLowerCase();
        if (!availableLocales.includes(l)) l = serverDefaultLocale.toLowerCase();
        return l;
    };

    const saveClientLocale = (locale) => {
        let l = checkLocale(locale);
        //Cookies.set(Miscellaneous.getStorageKey(StorageKeys.DefaultLanguage, false), l);
        localStorage.setItem(Miscellaneous.getStorageKey(StorageKeys.DefaultLanguage, false), l);
    };

    const setLocale = (locale) => {
        let l = checkLocale(locale);
        baseLocale(l); // NOTE VAT: need language to get culture from DB
        baseLocale(t("100155")); // NOTE VAT: setting culture from DB
        saveClientLocale(l);
    };

    const setDefaultCurrency = (defaultCurrency) => {
        config({ defaultCurrency: defaultCurrency, licenseKey });
        //Cookies.set(Miscellaneous.getStorageKey(StorageKeys.DefaultCurrency, false), defaultCurrency);
        localStorage.setItem(Miscellaneous.getStorageKey(StorageKeys.DefaultCurrency, false), defaultCurrency);
    };

    const formatMessage = useCallback((key, ...value) => {
        let message = baseFormatMessage(key, value);
        if (message === '') message = `${locale()}_${key}`;
        return message;
    }, []);

    const t = (...args) => formatMessage(...args);

    const formatDate = useCallback((value, format) => {
        return baseFormatDate(value, format);
    }, []);

    const formatNumber = useCallback((value, format) => {
        return baseFormatNumber(value, format);
    }, []);

    const getDefaultLocale = () => {
        //let defaultLanguage = Cookies.get(Miscellaneous.getStorageKey(StorageKeys.DefaultLanguage, false));
        let defaultLanguage = localStorage.getItem(Miscellaneous.getStorageKey(StorageKeys.DefaultLanguage, false));
        if (defaultLanguage !== null && defaultLanguage !== undefined) return defaultLanguage;
        return DefaultLanguage;
    };

    const getDefaultCurrency = () => {
        //let defaultCurrency = Cookies.get(Miscellaneous.getStorageKey(StorageKeys.DefaultCurrency, false));
        let defaultCurrency = localStorage.getItem(Miscellaneous.getStorageKey(StorageKeys.DefaultCurrency, false));
        if (defaultCurrency !== null && defaultCurrency !== undefined) return defaultCurrency;
        return DefaultCurrency;
    };

    if (defaultLocale === null || defaultLocale === undefined) defaultLocale = getDefaultLocale();
    if (defaultCurrency === null || defaultCurrency === undefined) defaultCurrency = getDefaultCurrency();
    if (dictionary === null || dictionary === undefined) dictionary = {};

    baseLoadMessages(deMessages);
    baseLoadMessages(enMessages);
    baseLoadMessages(frMessages);
    baseLoadMessages(itMessages);
    baseLoadMessages(dictionary);
    setLocale(defaultLocale);
    setDefaultCurrency(defaultCurrency);

    const shortDateShortTime = t("100150"); //Calendar.shortDateShortTime;
    const shortDateLongTime = t("100156"); //Calendar.shortDateLongTime;
    const shortDate = t("100151"); //Calendar.shortDate;
    const shortTime = t("100152"); //Calendar.shortTime;
    const decimalNumber1 = Number.decimalNumber1;
    const decimalNumber2 = Number.decimalNumber2;
    const decimalNumber3 = Number.decimalNumber3;
    //const decimalNumber2Presentation = "0.##";
    //const decimalNumber3Presentation = "0.###";

    return (
        <LocalizationContext.Provider value={{ t, formatMessage, formatDate, formatNumber, availableLocales, saveClientLocale, shortDateShortTime, shortDateLongTime, shortTime, shortDate, decimalNumber1, decimalNumber2, decimalNumber3 }} {...props} />
    );
}

export { LocalizationProvider, useLocalization, LocalizationContext, locale }
