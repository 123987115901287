import { AuthenticationService } from '../services/AuthenticationService';
import { HeaderNames, ApiActions } from '../helpers/Constants';
import { Mutex, withTimeout } from 'async-mutex';
import { locale } from '../contexts/LocalizationProvider';
// USE ONLY traceClient, infoClient, warnClient, errorClient LOGGING HERE,
// OTHERWISE IT WILL CAUSE ENDLESS LOOP WHILE SENDING TO SERVER
// import Log from '../utils/Log';

//const mutex = new Mutex();
const mutexWithTimeout = withTimeout(new Mutex(), 10000, new Error('timeout'));

const doFetchData = (resource: RequestInfo, init: RequestInit, responseType: string = 'json') => {
    return new Promise((resolve, reject) => {
        window.fetch(resource, init).then((response) => {
            if (!response.ok) {
                reject(response);
            } else if (responseType === 'json') {
                return response.json();
            } else if (responseType === 'blob') {
                return response.blob();
            } else {
                return response.text();
            }
        }).then((data) => {
            resolve(data);
        }).catch((error) => {
            reject(error);
        });
    });
};

const addHeaderForFetch = (init: RequestInit, name: string, value: string) => {
    let customInit: RequestInit = {};
    if (init.hasOwnProperty("headers")) {
        if (init.headers instanceof Headers) {
            let headers: Headers = init.headers as Headers;
            headers.append(name, value);
        } else {
            let headers: any = init.headers as any;
            headers[name] = value;
        }
    } else {
        let headers: Headers = new Headers();
        headers.append(name, value);
        customInit.headers = headers;
    }
    return { ...init, ...customInit };
}

export const addHeaderForXhr = (options: any, name: string, value: string) => {
    const oldHeaders: any = options.headers || {};
    const newHeades: any = {};
    newHeades[name] = value;

    return {
        ...options, ...{
            headers: { ...oldHeaders, ...newHeades }
        }
    };
}

export const addClientLanguageHeaderForXhr = (options: any) => {
    return addHeaderForXhr(options, HeaderNames.ClientLanguage, locale());
};

const addClientLanguageHeaderForFetch = (init: RequestInit) => {
    return addHeaderForFetch(init, HeaderNames.ClientLanguage, locale());
};

const handleNotAuthenticated = () => {
    AuthenticationService.logout();
};

export const refreshToken = () => {
    return mutexWithTimeout.runExclusive(function () {
        let initWithLanguageHeader = addClientLanguageHeaderForFetch({});
        return doFetchData(ApiActions.RefreshToken, initWithLanguageHeader);
    });
}

const getStatus = (response: XMLHttpRequest | Response) => {
    if (response && (response instanceof XMLHttpRequest
        || response instanceof Response)) {
        return response.status;
    } else {
        return null;
    }
}

export const refreshTokenAndTryAgain = (response: XMLHttpRequest | Response, originalRequest: any, textStatus: string | null = null) => {
    return new Promise((resolve, reject) => {
        // if unauthorized => call refresh token
        if (getStatus(response) == 401) {
            refreshToken().then((data: any) => {
                AuthenticationService.setCurrentUser(data);
                originalRequest().then(function (...args: any[]) {
                    resolve(args.length == 1 ? args[0] : args);
                }).catch(function (...args: any[]) {
                    if (getStatus(args[0]) == 401) {
                        handleNotAuthenticated();
                    } else {
                        reject(args.length == 1 ? args[0] : args);
                    }
                });
            }).catch((...args) => {
                handleNotAuthenticated();
            });
        } else {
            // status is not 401, call reject
            reject(textStatus != null ? [ response, textStatus ] : response);
        }
    });
}

export const fetchData = (resource: RequestInfo, init: RequestInit = {}, refreshToken: boolean = true, responseType: string = 'json') => {
    return new Promise((resolve, reject) => {
        let initWithHeaders = addClientLanguageHeaderForFetch(init);
        doFetchData(resource, initWithHeaders, responseType).then(data => {
            resolve(data);
        }).catch((error) => {
            if (refreshToken) {
                refreshTokenAndTryAgain(error, () => {
                    let initWithHeaders = addClientLanguageHeaderForFetch(init);
                    return doFetchData(resource, initWithHeaders, responseType);
                }).then(data => {
                    resolve(data);
                }).catch((error) => {
                    reject(error);
                });
            } else {
                reject(error);
            }
        });
    });
};

export const fetchNotAuthorizedData = (resource: RequestInfo, init: RequestInit = {}, responseType: string = 'json') => {
    return new Promise((resolve, reject) => {
        let initWithLanguageHeader = addClientLanguageHeaderForFetch(init);
        doFetchData(resource, initWithLanguageHeader, responseType).then(data => {
            resolve(data);
        }).catch((error) => {
            if (error.hasOwnProperty("status") && error.status == 401) {
                handleNotAuthenticated();
            } else {
                reject(error);
            }
        });
    });
};

export const getErrorData = (error: any, property: string = 'errorMessage') => {
    return new Promise((resolve, reject) => {
        error.json().then((obj: any) => {
            if (obj.hasOwnProperty(property)) {
                resolve(obj[property]);
            } else {
                resolve(null);
            }
        }).catch((error: any) => {
            resolve(null);
        });
    });
}