import { useState, useCallback, useReducer } from 'react';
import { useEffect, useRef } from 'react';
import { debounce } from 'throttle-debounce';
import { Type, Miscellaneous } from '../helpers';
import { EditMaxWidth, DefaultFormOptions } from '../components/edit/Defaults';
import { useLocalization } from '../contexts/LocalizationProvider';
import { usePrompt } from "./HooksTs";



export const usePropertyState = (initialValue = null, propertyName = '') => {
    const [value, _setValue] = useState(initialValue);
    const useEffect = useRef(true);
    const prevValue = usePrevious(value);

    const setValue = (data, newUseEffect = true) => {
        useEffect.current = newUseEffect;
        _setValue(data);
    };

    return [
        value,
        setValue,
        {
            propertyName,
            value,
            onValueChanged: useCallback(data => {
                //console.info(`usePropertyState:onValueChanged: ${propertyName}: '${data.value}'`);
                setValue(data.value);
            }, [])
        },
        prevValue,
        useEffect.current
    ];
};

const reducer = (prevState, nextState) => {
    if (Miscellaneous.isEqual(prevState, { ...prevState, ...nextState })) {
        return prevState;
    } else {
        return { ...prevState, ...nextState };
    }
};

export const useObjectState = (initialState) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const useEffectStateRef = useRef({});
    const prevState = usePrevious(state);

    const getValue = useCallback((propertyName) => {
        return state[propertyName];
    }, [state]);

    const getPrevValue = useCallback((propertyName) => {
        return !Type.isEmpty(prevState) && prevState.hasOwnProperty(propertyName) ? prevState[propertyName] : undefined;
    }, [prevState]);

    const getUseEffect = (propertyName) => {
        if (Type.isEmpty(useEffectStateRef.current) || !useEffectStateRef.current.hasOwnProperty(propertyName)) {
            return true;
        } else {
            return useEffectStateRef.current[propertyName];
        }
    };

    const setValue = useCallback((propertyName, value, useEffect = true) => {
        var useEffectObj = {};
        useEffectObj[propertyName] = useEffect;

        useEffectStateRef.current = { ...useEffectStateRef.current, ...useEffectObj };

        var obj = {};
        obj[propertyName] = value;

        dispatch(obj);
    }, []);

    const bindValue = useCallback((propertyName) => {
        return {
            propertyName,
            value: getValue(propertyName),
            onValueChanged: data => {
                //console.info(`useObjectState:onValueChanged: ${propertyName}: '${data.value}'`);
                //if (propertyName == 'customerId' && data.value == null)
                //    debugger;
                setValue(propertyName, data.value);
            }
        };
    }, [state]);

    const getBinding = (propertyName) => {
        return [
            getValue(propertyName),
            // useCallback((value, useEffect) => setValue(propertyName, value, useEffect), [propertyName, setValue]),
            (value, useEffect) => setValue(propertyName, value, useEffect), 
            bindValue(propertyName),
            getPrevValue(propertyName),
            getUseEffect(propertyName)
        ];
    };

    return [
        state,
        dispatch,
        getValue,
        setValue,
        getBinding
    ];
}

export const useDimension = (ref) => {
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
    const resizeObserverRef = useRef(null);

    useEffect(() => {
        resizeObserverRef.current = new ResizeObserver((entries = []) => {
            entries.forEach((entry) => {
                const { width, height } = entry.contentRect;
                onResize({ width: width, height: height });
            });
        });
        if (ref.current) resizeObserverRef.current.observe(ref.current);
        return () => {
            if (resizeObserverRef.current) resizeObserverRef.current.disconnect();
        };
    }, [ref]);

    const onResize = debounce(200, ({ width, height }) => {
        setDimensions({ width: width, height: height });
    });

    return dimensions;
};

/*const useTab = (id, tabId, mode, initialData, parentDisable, parentDataLoaded, selectedTab, binding = {}, addGridReference = undefined, setButtons = undefined) => {
    const [disable, setDisable] = useState(parentDisable);
    const [dataLoaded, setDataLoaded] = useState(false);

    return {
        id,
        value: selectedTab,
        index: tabId,
        tabId,
        mode,
        initialData,
        disable,
        setDisable,
        parentDisable,
        dataLoaded,
        setDataLoaded,
        parentDataLoaded,
        binding,
        addGridReference,
        setButtons,
        bindEditTab: {
            tabId,
            setDisable
        }
    };
}

export const useSimpleTab = (id, tabId, mode, initialData, parentDisable, parentDataLoaded, selectedTab, binding = {}, setButtons = undefined) => {
    const tabBinding = useTab(id, tabId, mode, initialData, parentDisable, parentDataLoaded, selectedTab, binding, undefined, setButtons);

    const { addGridReference, ...simpleTabBinding } = tabBinding;
    return simpleTabBinding;
}

export const useGridTab = (id, tabId, mode, initialData, parentDisable, parentDataLoaded, selectedTab, addGridReference, setButtons = undefined) => {
    const tabBinding = useTab(id, tabId, mode, initialData, parentDisable, parentDataLoaded, selectedTab, undefined, addGridReference, setButtons);

    const { bindEditTab, ...gridTabBinding } = tabBinding;
    return gridTabBinding;
}*/

export const useTabState = (initialValue = 0, initialValueParamName = 'tab') => {
    let initialTab = (new URLSearchParams(window.location.search)).get(initialValueParamName);
    if (Type.isEmpty(initialTab)) initialTab = initialValue;

    const [value, setValue] = useState(initialTab*1);

    const onChange = (event, newValue) => {
        setValue(newValue);
    };

    return [
        value,
        setValue,
        {
            value,
            onChange
        }
    ];
}

export const useEdit = (id, keyFieldName, loadUrl, saveUrl, getData, setData, cancel, onLoaded, onSaved, initialMode, setButtons = undefined) => {
    const [loading, setLoading] = useState(false);
    const [disable, setDisable] = useState(false);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [initialData, setInitialData] = useState({});
    const [mode, setMode] = useState(initialMode);
   
    return [
        loading,
        setLoading,
        disable,
        setDisable,
        dataLoaded,
        setDataLoaded,
        initialData,
        mode,
        {
            id,
            keyFieldName,
            loadUrl,
            saveUrl,
            getData,
            setData,
            setInitialData,
            cancel,
            loading,
            setLoading,
            setDisable,
            onLoaded,
            onSaved,
            mode,
            setMode,
            setButtons,
            tabbed: false
        }
    ];
};

export const useTabEdit = (id, keyFieldName, loadUrl, saveUrl, getData, setData, cancel, onLoaded, onSaved, initialMode, selectedTab, setSelectedTab, setButtons = undefined) => {
    const [state, setState, getValue, setValue, getBinding] = useObjectState({
        loading: false,
        disable: false,
        dataLoaded: false,
        initialData: {},
        mode: initialMode
    });
    const gridReferences = useRef([]);
    const handleServerValidationErrorRef = useRef(null);

    const addGridReference = useCallback((ref) => {
        gridReferences.current.push(ref);
    }, []);

    const setHandleServerValidationError = (handleServerValidationError) => {
        handleServerValidationErrorRef.current = handleServerValidationError;
    }

    const [loading, setLoading] = getBinding('loading');
    const [disable, setDisable] = getBinding('disable');
    const [dataLoaded, setDataLoaded] = getBinding('dataLoaded');
    const [initialData, setInitialData] = getBinding('initialData');
    const [mode, setMode] = getBinding('mode');

    return [
        loading,
        setLoading,
        disable,
        setDisable,
        dataLoaded,
        setDataLoaded,
        initialData,
        mode,
        addGridReference,
        handleServerValidationErrorRef.current,
        {
            id,
            keyFieldName,
            loadUrl,
            saveUrl,
            getData,
            setData,
            setInitialData,
            cancel,
            loading,
            setLoading,
            setDisable,
            onLoaded,
            onSaved,
            mode,
            setMode,
            setButtons,
            selectedTab,
            setSelectedTab,
            gridReferences,
            tabbed: true,
            setHandleServerValidationError
        },
        setMode
    ];
};

export const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}

export const useTabForm = (tabId, maxWidth = EditMaxWidth) => {
    return {
        ...DefaultFormOptions,
        ...{
            elementAttr: { style: `max-width: ${maxWidth}px` },
            id: `form-tab-${tabId}`
        }
    };
}