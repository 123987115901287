export const Identifier = 'Syfex.BTS.BTSweb';
export const DefaultLanguage = navigator.language;
export const DefaultCurrency = 'EUR';

export const Limits = {
    MinDate: new Date(1753, 0, 1),
    MaxDate: new Date(9999, 11, 31)
};

export const QueryParameterNames = {
    ReturnUrl: 'returnUrl'
};

export const HeaderNames = {
    ClientLanguage: 'clientLanguage'
};

export const CookieNames = {
    AccessToken: `accessToken`
};

export const StorageKeys = {
    AppMenuState: 'AppMenuState',
    DefaultLanguage: `defaultLanguage`,
    DefaultCurrency: `defaultCurrency`,
    Login: `login`,
    DoNotSaveLogin: `doNotSaveLogin`,
    EquipmentDepartmentFilter: 'equipmentDepartmentFilter',
    EquipmentBuildingCustomerFilter: 'equipmentBuildingCustomerFilter',
    OrderSteuerungFilter: 'orderSteuerungFilter',
    OrderRecallFilter: 'orderRecallFilter',
    SchedulerShowDisplayedHours: 'schedulerShowDisplayedHours',
    SchedulerShowWeekend: 'schedulerShowWeekend',
    SchedulerShowRecallPool: 'SchedulerShowRecallPool',
    SchedulerShowOrderPool: 'SchedulerShowOrderPool',
    SchedulerShowAbwesenheitPool: 'SchedulerShowAbwesenheitPool',
    SchedulerShowUnproduktivPool: 'SchedulerShowUnproduktivPool',
    SchedulerShowUserBlockPool: 'SchedulerShowUserBlockPool',
    SchedulerShowMiscellaneousPool: 'SchedulerShowMiscellaneousPool',
    SchedulerUserRoleFilter: 'SchedulerUserRoleFilter',
    SchedulerShowPools: 'SchedulerShowPools',
    SchedulerMaximized: 'SchedulerMaximized',
    ReportFilter: 'reportFilter',
    ReportOrderIdFilter: 'reportOrderIdFilter',
    AppointmentFilter: 'appointmentFilter',
    AppointmentWeekFilter: 'appointmentWeekFilter',
    AppointmentOrderIdFilter: 'appointmentOrderIdFilter'
};

export const GridColumnResizingMode = process.env.REACT_APP_GRID_COLUMN_RESIZING_MODE === 'true';
export const Basename = process.env.REACT_APP_BASENAME.replace(/\/$/, '');
export const LogLevelClient = process.env.REACT_APP_LOG_LEVEL_CLIENT;
export const LogLevelServer = process.env.REACT_APP_LOG_LEVEL_SERVER;

export const LogLevels = {
    trace: 'trace',
    info: 'info',
    warn: 'warn',
    error: 'error'
};

const ApplicationApiPrefix = `${Basename}/applicationApi`;
const AuthenticationApiPrefix = `${Basename}/authenticationApi`;
const LogApiPrefix = `${Basename}/logApi`;

export const Actions = {
    Login: `/login`,
    Logout: `/logout`,
    Refresh: `/refresh`,
};

export const OrderStatus = {
    STATUS_NEW: '0',
    STATUS_PLANNED: `10`,
    STATUS_CLOSED: `120`
};

export const OrderType = {
    RECALL: 'REC',
    ABWESENHEIT: 'ABW',
    PROJEKT: 'PRO',
    STOERUNG: 'STO',
    UNPRODUKTIV: 'UNP',
    MISCELLANEOUS: 'MISC',
    WARTUNG: 'WAR',
    VERMIETUNG: 'VER'
};

export function IsRealOrder(orderTypeId: string): boolean {
    return orderTypeId === OrderType.STOERUNG || orderTypeId === OrderType.PROJEKT || orderTypeId === OrderType.WARTUNG || orderTypeId === OrderType.VERMIETUNG;
}

export const AppointmentType = {
    RECALL: 'RECALL',
    ORDER: 'ORDER',
    USERBLOCK: 'USERBLOCK'
};

export const EquipmentStatus = {
    TEMPORARY: '0',
    DEFINITIVE: '1'
};

export const UserRole = {
    ADMINISTRATOR: '0',
    INNENDIENST: '1',
    TECHNIKER: '2',
    AVOR: '3',
    CHEFMONTEUR: '4'
};


export const RecallMaUserId = -10000;
export const RecallWUserId = -9999;
export const HolidayUserId = -9998;

export const ReportStatus = {
    OPEN: "0",
    WORK_NOT_FINISHED: "10",
    MATERIAL_MISSING: "20",
    WAITING_FOR_ORDER: "30",
    FINISHED: "40"
};



export const ApiActions = {
    Initialize: `${ApplicationApiPrefix}/initialize`,
    GetTitle: `${ApplicationApiPrefix}/getTitle`,
    Log: `${LogApiPrefix}/log`,
    Authenticate: `${AuthenticationApiPrefix}/authenticate`,
    RefreshToken: `${AuthenticationApiPrefix}/refreshToken`,
    RevokeToken: `${AuthenticationApiPrefix}/revokeToken`,
};

export const ClaimTypes = {
    Id: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier',
    FirstName: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname',
    LastName: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/surname',
    UserName: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name',
    Role: 'http://schemas.microsoft.com/ws/2008/06/identity/claims/role',
    IsPersistent: 'http://schemas.microsoft.com/ws/2008/06/identity/claims/ispersistent',
    Language: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/locality',
    Control: 'http://schemas.xmlsoap.org/ws/custom/identity/claims/control',
    Mandant: 'http://schemas.xmlsoap.org/ws/custom/identity/claims/mandant'
}

//Synchronize with ControlType.cs -> ControlType
export const Controls = {
    UserGrid: 10,
    UserEdit: 11,
    UserEditOldPasswordNotRequired: 12,
    CustomerGrid: 20,
    CustomerEdit: 21,
    CustomerLocationGrid: 30,
    CustomerLocationEdit: 31,
    CustomerSalesDocGrid: 32,
    CustomerSalesDocEdit: 33,
    LocationGrid: 40,
    LocationEdit: 41,
    BuildingGrid: 50,
    BuildingEdit: 51,
    RoomGrid: 60,
    RoomEdit: 61,
    EquipmentDefinitiveGrid: 70,
    EquipmentDefinitiveEdit: 71,
    EquipmentTemporaryGrid: 72,
    EquipmentTemporaryEdit: 73,
    EquipmentTemporarySelfGrid: 74,
    EquipmentTemporarySelfEdit: 75,
    EquipmentLookupLabelGrid: 80,
    EquipmentLookupLabelEdit: 81,
    OrderStoerungGrid: 90,
    OrderStoerungEdit: 91,
    OrderRecallGrid: 100,
    OrderRecallEdit: 101,
    OrderStoerungEquipmentGrid: 102,
    OrderStoerungEquipmentEdit: 103,
    OrderRecallEquipmentGrid: 104,
    OrderRecallEquipmentEdit: 105,
    OrderRecallStatusSet: 106,
    OrderStoerungStatusSet: 107,

    Scheduler: 110,
    OrderPool: 111,
    RecallPool: 112,
    AbwesenheitPool: 113,
    UnproduktivPool: 114,
    UserBlockPool: 116,

    MiscellaneousPool: 119,
    ReportGrid: 120,
    ReportEdit: 121,
    ReportFinishedEdit: 122,
    ReportSelfGrid: 123,
    ReportSelfEdit: 124,
    ReportEquipmentGrid: 130,
    ReportEquipmentEdit: 131,
    TimePosGrid: 140,
    TimePosEdit: 141,
    TimePosPriceEdit: 142,
    MaterialPosGrid: 150,
    MaterialPosEdit: 151,
    MaterialPosPriceEdit: 152,
    AppointmentGrid: 160,
    AppointmentSelfGrid: 160,
    HoursGrid: 170,
    HoursSelfGrid: 171,
    HoursReview: 172,
    AttachmentGrid: 180,
    AttachmentEdit: 181
};

export enum AccessRightType {
    NoAccess = 0,
    ReadAccess = 1,
    FullAccess = 2
};

export const MenuModes = {
    Context: 0,
    List: 1
};

export const ViewModes = {
    desktop: 0,
    ios: 1,
    android: 2
};
