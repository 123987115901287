import React, { useState } from 'react';
import ContextMenu, { Position } from 'devextreme-react/context-menu';
import List from 'devextreme-react/list';
import './userPanel.scss';
import { AuthenticationService } from '../../services'
import { Actions, MenuModes } from '../../helpers/Constants'
import { Miscellaneous } from '../../helpers'
import { useNavigate } from 'react-router-dom';
import { useLocalization } from '../../contexts/LocalizationProvider';
import DropDownButton from 'devextreme-react/drop-down-button';
import Button from 'devextreme-react/button';
import PasswordChanger from '../passwordChanger/PasswordChanger'

export default function UserPanel({ menuMode }) {
    const history = useNavigate();
    const { t } = useLocalization();

    const [popupVisible, setPopupVisible] = useState(false);

    const name = `${AuthenticationService.currentUser.lastName}, ${AuthenticationService.currentUser.firstName}`;

    const items = [{
        text: t(400004),
        navigate: (e) => {
            setPopupVisible(true);
        },
        active: false
    }, {
        text: t(100057),
        navigate: (e) => {
            Miscellaneous.navigate(Actions.Logout, history, e.event, false);
        },
        active: false
    }];

    return (
        <>
            {menuMode == MenuModes.Context ? <ContextUserPanel items={items} name={name} /> : <ListUserPanel items={items} name={name} />}
            <PasswordChanger popupVisible={popupVisible} setPopupVisible={setPopupVisible} />
        </>
        
    );
};

function ContextUserPanel({ items, name }) {
    return (
        <DropDownButton
            type="normal"
            stylingMode="contained"
            icon="user"
            text={name}
            items={items}
            displayExpr={'text'}
            onItemClick={(e) => {
                if (!e.itemData.active) {
                    e.itemData.navigate(e);
                }
            }}
            itemRender={(item) => {
                return <div style={{ paddingRight: '10px' }} className={item.active ? 'active' : ''} >{item.text}</div>
            }}
        />
    );
};

function ListUserPanel({ items, name }) {
    const { t, formatCurrency } = useLocalization();
    return (
        <List
            className="user-menu-list"
            focusStateEnabled={false}
            grouped={true}
            collapsibleGroups={true}
            groupRender={(item) => <div>{item.key}</div>}
            itemRender={(item) => {
                return <Button
                    type={item.active ? 'default' : 'normal'}
                    stylingMode="contained"
                    text={item.text}
                    style={{ width: '100%', height: 36, textAlign: 'left' }}
                />
            }}
            onGroupRendered={(e) => e.component.collapseGroup(e.groupIndex)}
            onItemClick={(e) => {
                if (!e.itemData.active) {
                    e.itemData.navigate(e);
                }
            }}
            dataSource={[
                {
                    key: name,
                    items: items
                }
            ]}
        />
    );
};