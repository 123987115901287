export const DefaultValidationGroup = 'editFormValidationGroup';
export const EditMaxWidth = 800;

export const DefaultFormOptions = {
    id: 'form',
    showColonAfterLabel: false,
    labelLocation: 'top',
    minColWidth: EditMaxWidth,
    colCount: 'auto',
    validationGroup: DefaultValidationGroup,
    elementAttr: {
        style: `max-width: ${EditMaxWidth}px`
    }
};

export const Mode = {
    create: 0,
    edit: 1,
    view: 2
}