import * as React from 'react';
import { withRouter } from '../../helpers/Patches';
import NotFound from '../../views/error/NotFound';
import AuthorizeRoute from './AuthorizeRoute';
import { LoadPanel } from 'devextreme-react/load-panel';
import { Miscellaneous } from '../../helpers';
import {
    Route,
    useParams
} from "react-router-dom";

function LazyLoadPanel() {
    return (
        <LoadPanel
            position={{ of: '.dx-drawer-content' }}
            visible={true}
            showIndicator={true}
            shading={false}
            showPane={false}
        />
    );
}

function SubRouter({ route, components, ...restProps }) {
    let { entity, view, id } = useParams();
    if (view === undefined) view = '';
    const path = [entity, view].filter(Boolean).join('/');
    const viewName = [entity, view].filter(Boolean).join('');

    const findFirstRoute = (arr) => (
        arr.reduce((a, o) => {
            if (a) return a;
            if (o.path === (path)) return o;
            if (o.items) return findFirstRoute(o.items);
        }, null)
    );

    const component = components[viewName];
    if (!component) return <NotFound />

    const viewRoute = findFirstRoute(route.items);
    if (!viewRoute) return <NotFound />

    const componentWithId = React.cloneElement(component, {
        id: id
    });

    return <AuthorizeRoute control={viewRoute.controls} {...restProps} render={(props) => (
        <React.Suspense fallback={<LazyLoadPanel />}>{componentWithId}</React.Suspense>
    )} /> 
}
//export default withRouter(SubRouter);
export default React.memo(SubRouter, Miscellaneous.isEqual);