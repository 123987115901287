/**
 * Returns the given value itself if it's not empty; returns the default
 * value (second argument) otherwise.
 *
 * @param {Object} value The value to test.
 * @param {Object} defaultValue The value to return if the original value is empty.
 * @param {Boolean} [allowBlank=false] (optional) `true` to allow zero length strings to qualify as non-empty.
 * @return {Object} `value`, if non-empty, else `defaultValue`.
 */
window.valueOrDefault = function (value, defaultValue, allowBlank) {
    return window.isEmpty(value, allowBlank) ? defaultValue : value;
};

/**
 * Returns the type of the given variable in string format. List of possible values are:
 *
 * - `undefined`: If the given value is `undefined`
 * - `null`: If the given value is `null`
 * - `string`: If the given value is a string
 * - `number`: If the given value is a number
 * - `boolean`: If the given value is a boolean value
 * - `date`: If the given value is a `Date` object
 * - `function`: If the given value is a function reference
 * - `object`: If the given value is an object
 * - `array`: If the given value is an array
 * - `regexp`: If the given value is a regular expression
 * - `element`: If the given value is a DOM Element
 * - `textnode`: If the given value is a DOM text node and contains something other than whitespace
 * - `whitespace`: If the given value is a DOM text node and contains only whitespace
 *
 * @param {Object} value
 * @return {String}
 */
window.typeOf = function (value) {
    if (value === null) {
        return 'null';
    }

    var type = typeof value;

    if (type === 'undefined' || type === 'string' || type === 'number' || type === 'boolean') {
        return type;
    }

    var typeToString = toString.call(value);

    switch (typeToString) {
        case '[object Array]':
            return 'array';
        case '[object Date]':
            return 'date';
        case '[object Boolean]':
            return 'boolean';
        case '[object Number]':
            return 'number';
        case '[object RegExp]':
            return 'regexp';
    }

    if (type === 'function') {
        return 'function';
    }

    if (type === 'object') {
        if (value.nodeType !== undefined) {
            if (value.nodeType === 3) {
                return (/\S/).test(value.nodeValue) ? 'textnode' : 'whitespace';
            }
            else {
                return 'element';
            }
        }

        return 'object';
    }
};

/**
 * Returns `true` if the passed value is empty, `false` otherwise. The value is deemed to be empty if it is either:
 *
 * - `null`
 * - `undefined`
 * - a zero-length array.
 * - a zero-length string (Unless the `allowEmptyString` parameter is set to `true`).
 *
 * @param {Object} value The value to test.
 * @param {Boolean} [allowEmptyString=false] (optional) `true` to allow empty strings.
 * @return {Boolean}
 */
window.isEmpty = function (value, allowEmptyString) {
    return (value === null) || (value === undefined) || (!allowEmptyString ? value === '' : false) || (window.isArray(value) && value.length === 0);
};

/**
 * Returns `true` if the passed value is a JavaScript Array, `false` otherwise.
 *
 * @param {Object} target The target to test.
 * @return {Boolean}
 * @method
 */
window.isArray = ('isArray' in Array) ? Array.isArray : function (value) {
    return toString.call(value) === '[object Array]';
};

/**
 * Returns `true` if the passed value is a JavaScript Date object, `false` otherwise.
 * @param {Object} object The object to test.
 * @return {Boolean}
 */
/*window.isDate = function (value) {
    return toString.call(value) === '[object Date]';
};*/

/**
 * Returns 'true' if the passed value is a String that matches the MS Date JSON encoding format
 * @param {String} value The string to test
 * @return {Boolean}
 */
window.isMSDate = function (value) {
    if (!window.isString(value)) {
        return false;
    } else {
        return value.match("\\\\?/Date\\(([-+])?(\\d+)(?:[+-]\\d{4})?\\)\\\\?/") !== null;
    }
};

/**
 * Returns `true` if the passed value is a JavaScript Object, `false` otherwise.
 * @param {Object} value The value to test.
 * @return {Boolean}
 * @method
 */
window.isObject = (toString.call(null) === '[object Object]') ?
    function (value) {
        // check ownerDocument here as well to exclude DOM nodes
        return value !== null && value !== undefined && toString.call(value) === '[object Object]' && value.ownerDocument === undefined;
    } :
    function (value) {
        return toString.call(value) === '[object Object]';
    };

/**
 * @private
 */
window.isSimpleObject = function(value) {
        return value instanceof Object && value.constructor === Object;
};

/**
 * Returns `true` if the passed value is a JavaScript 'primitive', a string, number or Boolean.
 * @param {Object} value The value to test.
 * @return {Boolean}
 */
window.isPrimitive = function(value) {
    var type = typeof value;

    return type === 'string' || type === 'number' || type === 'boolean';
};

/**
 * Returns `true` if the passed value is a JavaScript Function, `false` otherwise.
 * @param {Object} value The value to test.
 * @return {Boolean}
 * @method
 */
window.isFunction =
    // Safari 3.x and 4.x returns 'function' for typeof <NodeList>, hence we need to fall back to using
    // Object.prorotype.toString (slower)
    (typeof document !== 'undefined' && typeof document.getElementsByTagName('body') === 'function') ? function (value) {
        return toString.call(value) === '[object Function]';
    } : function (value) {
        return typeof value === 'function';
    };

/**
 * Returns `true` if the passed value is a number. Returns `false` for non-finite numbers.
 * @param {Object} value The value to test.
 * @return {Boolean}
 */
window.isNumber = function (value) {
    return typeof value === 'number' && isFinite(value);
};

/**
 * Validates that a value is numeric.
 * @param {Object} value Examples: 1, '1', '2.34'
 * @return {Boolean} `true` if numeric, `false` otherwise.
 */
window.isNumeric = function (value) {
    return !isNaN(parseFloat(value)) && isFinite(value);
};

/**
 * Returns `true` if the passed value is a string.
 * @param {Object} value The value to test.
 * @return {Boolean}
 */
window.isString = function (value) {
    return typeof value === 'string';
};

/**
 * Returns `true` if the passed value is a Boolean.
 *
 * @param {Object} value The value to test.
 * @return {Boolean}
 */
window.isBoolean = function (value) {
    return typeof value === 'boolean';
};

/**
 * Returns `true` if the passed value is defined.
 * @param {Object} value The value to test.
 * @return {Boolean}
 */
window.isDefined = function (value) {
    return typeof value !== 'undefined';
};


/*Object.defineProperty(String.prototype, "valueOrDefault", {
    value: function valueOrDefault(defaultValue, allowBlank) {
        return window.valueOrDefault(this+'', defaultValue, allowBlank);
    },
    writable: true,
    configurable: true
});*/