﻿import { Miscellaneous } from './Miscellaneous';
import { Type } from './Type';
import { Basename } from './Constants';

const join = (separator: string, array: Array<string>): string => {
    return array.filter((item) => item != null && item != '').join(separator);
}

export const arrayToQueryString = (key: string, array: Array<string | number>, startingOperator: string = '?'): string => {
    if (array == null || array.length == 0) return '';
    array = array.map(encodeURIComponent)
    return startingOperator + key + '=' + array.join('&' + key + '=')
}

export const objectToQueryString = (object: any, startingOperator: string = '?', removeNullValues: boolean = true): string => {
    const keys = removeNullValues ? Miscellaneous.getNotEmptyKeys(object) : Object.keys(object);
    if (object == null || keys.length == 0) return '';
    return startingOperator + keys.map(key => {
        if (Type.isArray(object[key])) {
            return arrayToQueryString(key, object[key], '');
        } else {
            return `${key}=${encodeURIComponent(object[key])}`;
        }
    }).join('&');
}

export const appendToUrl = (url: string, param: any = null) => {
    const normalizedUrl = url.startsWith('/') ? url : `/${url}`;
    const absoluteUrl = `${window.location.origin}${Basename}${normalizedUrl}`;
    const urlObject = new URL(absoluteUrl);
    const params: any = {};

    /*urlObject.searchParams.forEach(function (value, key) {
        params[key] = value;
    });*/
    urlObject.searchParams.forEach(function (value, key) {
        const values = urlObject.searchParams.getAll(key);
        params[key] = values;
    });

    return `${url.replace(urlObject.search, '')}${Type.isEmpty(param) ? '' : `/${param}`}${Text.objectToQueryString(params)}`;
};

export const hexStringToText = (hexString: string): string => {
    const isHex = (maybeHex:string) =>
        maybeHex.length !== 0 && maybeHex.length % 2 === 0 && !/[^a-fA-F0-9]/u.test(maybeHex);

    const fromHexString = (hexString: string) =>
        Uint8Array.from(hexString.match(/.{2}/g).map((byte) => parseInt(byte, 16)));

    if (!isHex(hexString)) {
        console.log(`The string "${hexString}" is not valid hex.`)
        return "";
    } else {
        let decoder = new TextDecoder("utf-8");
        let str = decoder.decode(fromHexString(hexString));
        return str;
    }
 
};

export const textToHexString = (text: string): string => {
    const toHexString = (bytes:Uint8Array) =>
        bytes.reduce((str, byte) => str + byte.toString(16).padStart(2, '0'), '');
    let encoder = new TextEncoder();
    let hexString = toHexString(encoder.encode(text));
    return hexString;
};


const Text = {
    join: join,
    arrayToQueryString: arrayToQueryString,
    objectToQueryString: objectToQueryString,
    appendToUrl: appendToUrl,
    hexStringToText: hexStringToText,
    textToHexString: textToHexString
}

export { Text };
