import React from 'react';
import Toolbar, { Item } from 'devextreme-react/toolbar';
import Button from 'devextreme-react/button';
import UserPanel from '../userPanel/UserPanel';
import './header.scss';
import { Template } from 'devextreme-react/core/template';
import { useNavigate } from 'react-router-dom';
import { withRouter } from '../../helpers/Patches';
import { MenuModes, Basename } from '../../helpers/Constants'
import { Miscellaneous } from '../../helpers'

const Header = ({ menuToggleEnabled, title, toggleMenu, menuItems, location }) => {
    const history = useNavigate();

    const links = menuItems.filter(o => o.route).map(o => (
        <Item
            key={o.route.path}
            widget="dxButton"
            location="before"
            locateInMenu="auto"
            cssClass="toolbar-main-menu-button"
            options={{
                onClick: (e) => {
                    Miscellaneous.navigate(o.route.path, history, e.event);
                },
                type: location.pathname.startsWith(`/${o.route.path}`) ? "default" : "normal",
                stylingMode: "contained",
                text: o.text
            }}
        />
    ));

    return (
        <header className={'header-component'}>
            <Toolbar className={'header-toolbar'}>
                <Item
                    visible={menuToggleEnabled}
                    location={'before'}
                    widget={'dxButton'}
                    cssClass={'menu-button'}
                >
                    <Button icon="menu" stylingMode="text" onClick={toggleMenu} />
                </Item>
                <Item
                    location={'before'}
                    locateInMenu={'never'}
                    cssClass={'header-title'}
                >
                    <img src="logo.svg" alt="Optimo logo"/>
                </Item>
                {links}
                <Item
                    location={'after'}
                    locateInMenu={'auto'}
                    menuItemTemplate={'userListTemplate'}
                    template={'userContextTemplate'}
                    cssClass="toolbar-main-menu-button"
                />
                <Template name={'userContextTemplate'}>
                    <UserPanel menuMode={MenuModes.Context} />
                </Template>
                <Template name={'userListTemplate'}>
                    <UserPanel menuMode={MenuModes.List} />
                </Template>


                {/*<Item
                    location={'after'}
                    locateInMenu={'auto'}
                    menuItemTemplate={'userPanelTemplate'}
                >
                    <Button
                        className={'user-button authorization'}
                        height={36}
                        stylingMode={'text'}
                    >
                        <UserPanel menuMode={'context'} />
                    </Button>
                </Item>
                <Template name={'userPanelTemplate'}>
                    <UserPanel menuMode={'list'} />
                </Template>*/}
            </Toolbar>
        </header>
    );
};

export default withRouter(Header)

